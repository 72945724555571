import { FC, PropsWithChildren } from 'react';

import {
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  InputLabel,
  RadioGroup as MUIRadioGroup,
  Radio,
  RadioGroupProps,
} from '@mui/material';

interface Props extends Pick<RadioGroupProps, 'onChange'>, Pick<FormControlLabelProps, 'disabled'> {
  labelText: string;
  value?: boolean | null;
  errorMessage?: string;
  required?: boolean;
}

enum OptionValue {
  YES = 'Yes',
  NO = 'No',
}

export const YesNoRadioGroup: FC<PropsWithChildren<Props>> = ({
  value,
  labelText,
  onChange,
  disabled,
  errorMessage,
  required,
}) => (
  <Grid sx={{ mt: 2 }} container>
    <Grid item xs={false} sm={6} md={6}>
      <InputLabel disabled={disabled} error={Boolean(errorMessage)}>
        {labelText}
        {required && !disabled && ' (Required)'}
      </InputLabel>
      <MUIRadioGroup row value={String(value)} onChange={onChange}>
        {Object.values(OptionValue).map(value => (
          <FormControlLabel
            key={value}
            value={value === OptionValue.YES}
            control={<Radio />}
            label={value}
            disabled={disabled}
          />
        ))}
      </MUIRadioGroup>
    </Grid>
  </Grid>
);
