import { FC } from 'react';

import { Card, CardContent } from '@mui/material';

import { GridItemView } from 'components/common';
import { EstimatedTime, ID, OptionalForUser, Tags, Title } from 'components/inputs';
import { ActionButtons } from 'components/inputs/ActionButtons';
import { ImageInput } from 'components/post/inputs';
import { ViewTable } from 'components/tables/view-table';
import { setLabel } from 'global/form';
import { formatDate } from 'global/formater';
import { PathParams } from 'global/route';
import { PACKAGE_COLUMNS } from 'global/type';
import { checkStringBool, isTagsValid } from 'utils';

import { MomentFormData, MomentKey } from '../forms';
import { useMomentForm } from '../forms/useMomentForm';
import { MultilinePrompt } from '../inputs/MultilinePrompt';
import { YesNoRadioGroup } from 'components/inputs/YesNoRadioGroup';

interface Props {
  action: PathParams;
  providedFormValues: MomentFormData;
}

export const Moment: FC<Props> = ({ providedFormValues, action }) => {
  const {
    watch,
    setTags,
    setPrompts,
    formActions,
    setValue,
    trigger,
    errors,
    disabled,
    watchThumbnail,
    thumbnailSubmitHandler,
    thumbnailDeleteHandler,
    thumbnailChangeHandler,
  } = useMomentForm({
    providedFormValues: providedFormValues,
    action,
  });

  const {
    uid,
    title,
    introduction,
    durationMs,
    prompts,
    tags,
    packages,
    thumbnail,
    updatedAt,
    createdAt,
    optionalForUser,
    allowAttachments,
  } = watch();
  const { url: thumbnailUrl } = watchThumbnail();

  return (
    <Card>
      <CardContent sx={{ mb: '35px' }}>
        {uid && <ID value={uid} disabled />}
        <Title
          label={setLabel('Title', action)}
          value={title}
          onChange={e => {
            setValue(MomentKey.TITLE, e.target.value);
          }}
          onBlur={() => trigger(MomentKey.TITLE)}
          helperText={errors?.title?.message}
          error={Boolean(errors.title)}
          disabled={disabled}
        />
        <Title
          value={introduction}
          label={'Introduction'}
          onChange={e => {
            setValue(MomentKey.INTRODUCTION, e.target.value);
          }}
          onBlur={() => trigger(MomentKey.INTRODUCTION)}
          helperText={errors?.introduction?.message}
          error={Boolean(errors.introduction)}
          disabled={disabled}
        />
        <EstimatedTime
          value={durationMs}
          label={setLabel('Estimated time', action)}
          onChange={e => setValue(MomentKey.SUGGESTED_LENGTH, Math.abs(Number(e.target.value)) || '')}
          onBlur={() => trigger(MomentKey.SUGGESTED_LENGTH)}
          helperText={errors.durationMs?.message}
          error={Boolean(errors.durationMs)}
          disabled={disabled}
        />
        <OptionalForUser
          disabled={disabled}
          value={optionalForUser}
          onChange={e => setValue(MomentKey.OPTIONAL_FOR_USER, checkStringBool(e.target.value))}
        />
        <YesNoRadioGroup
          disabled={disabled}
          value={allowAttachments}
          labelText={'Allow media attachments'}
          onChange={e => setValue(MomentKey.ALLOW_ATTACHMENTS, checkStringBool(e.target.value))}
        />
        <ImageInput
          onSubmit={thumbnailSubmitHandler}
          onDelete={thumbnailDeleteHandler}
          onChange={thumbnailChangeHandler}
          url={thumbnail?.url || thumbnailUrl}
          imagePreviewProps={{ label: 'Thumbnail' }}
          disabled={disabled}
        />
        <GridItemView
          style={{ marginTop: 12 }}
          label="Tag"
          item={<Tags tags={isTagsValid(tags) ? tags : []} setTags={setTags} disabled={disabled} />}
        />
        {disabled && (
          <>
            <GridItemView label="Updated" item={formatDate(String(updatedAt))} />
            <GridItemView label="Created" item={formatDate(String(createdAt))} />
          </>
        )}

        {disabled && <ViewTable rows={packages ?? []} columns={PACKAGE_COLUMNS} label={'Packages:'} />}
        <MultilinePrompt
          label={setLabel('Prompts', action)}
          prompts={prompts ?? []}
          setCurrentPrompts={setPrompts}
          disabled={disabled}
          error={Boolean(errors.prompts)}
          helperText={errors.prompts?.message}
          onBlur={() => trigger(MomentKey.PROMPTS)}
        />
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
